<template>
    <div>
        <div class="row justify-content-center">
          <div class="col-md-3" v-if="['SuperAdmin','Admin'].includes($store.state.users.level)">
              <p class="text-center font-bold p-2">Transaksi penjualan/Keluar</p>
              <p class="text-sm bg-white p-3 rounded-lg shadow hover:bg-blue-300 anim capitalize cursor-pointer" 
              @click="$router.push('/admin/transfer/penjualan/periode')">
              <span class="typcn typcn-chart-area"></span> Report penjualan Perperiode</p>
             <hr>
             <p class="text-sm bg-white p-3 rounded-lg shadow hover:bg-blue-300 anim capitalize cursor-pointer" 
             @click="$router.push('/admin/transfer/penjualan/perincian')">
              <span class="typcn typcn-chart-area"></span> Report penjualan Perincian</p>
             <hr>
              <p class="text-sm bg-white p-3 rounded-lg shadow hover:bg-blue-300 anim capitalize cursor-pointer" 
             @click="$router.push('/admin/transfer/penjualan/produk')">
              <span class="typcn typcn-chart-area"></span> Report penjualan Per Produk</p>
             <hr>
              <p class="text-sm bg-white p-3 rounded-lg shadow hover:bg-blue-300 anim capitalize cursor-pointer" 
              @click="$router.push('/admin/transfer/transaksi')">
              <span class="typcn typcn-chart-area"></span> Report Transaksi</p>
             <hr>
               <p class="text-sm bg-white p-3 rounded-lg shadow hover:bg-blue-300 anim capitalize cursor-pointer" 
             @click="$router.push('/admin/transfer/penjualan/kasir')">
              <span class="typcn typcn-chart-area"></span> Report penjualan Per Kasir</p>
             <hr>
         </div>
        </div>
    </div>
</template>
<script>
export default{
    layout:'app_shop_admin'
}
</script>